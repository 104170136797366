import * as React from 'react';
import {Jumbotron, Row, Container, Button, Col} from 'react-bootstrap';
import Header from '../layouts/header';
import Footer from '../layouts/footer';

class error404Element extends React.PureComponent<{}, {}> {

    render() {
        const headerStyle = {
            fontSize: '7rem',
            color: '#fff'
        }
        const subheaderStyle = {
            fontSize: '3rem',
            color:'#fff'
        }
        const buttonsContainer = {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'space-evenly',
            width: '20%',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
        const jumbotronStyles = {
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            height: '70vh',
            backgroundImage: 'linear-gradient(312deg, #F9A158 10%, #e75439 48%, #d9242a 70%)'
            
        }
        const containerStyles = {
            height: '40%',
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'space-between'
        }
        const btn = {
            backgroundColor: '#b02227'
        }
        const borderBtn = {
            border: '1px solid #b02227'
        }
        const Image404 = {
            width: '300px'
        }
        const title404Row = {
            display: 'flex',
            flexFlow:'row wrap',
            justifyContent: 'center'
        }

        return (<>
            <Header/>
            <Jumbotron style={jumbotronStyles} fluid="true">
                    <Container style={containerStyles}>
                        <Row style={title404Row}>
                            <div class="404-title">
                                <h1 style={headerStyle}>Oops... Something went wrong</h1>
                                <h5 style={subheaderStyle}>The page you're looking for doesn't exist. <br/>Are you lost? </h5> 
                            </div>
                            <div>
                                <img style={Image404} src="images/platformhero.png"></img>
                            </div>
                        </Row>
                        
                        <Row>
                            <div style={buttonsContainer}>
                                <Col>
                                    <Button style={borderBtn} onClick={this.goBack} variant="outline-danger">Go Back</Button>
                                </Col>
                                <Col>
                                    <Button style={btn} href="/" variant="danger">Home</Button>
                                </Col>
                                
                            </div>
                        </Row>
                    </Container>
                </Jumbotron>
                <Footer/></>
        )
    }
    private goBack = () => {
        window.history.back();
    }
}
export default error404Element;
